
<template>
    <div class="page-wrapper">
                <div class="page-content">
                    <!--breadcrumb-->
                    <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div class="breadcrumb-title pe-3">Ventas</div>
                        <div class="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb mb-0 p-0">
                                    <li class="breadcrumb-item"><a href="javascript:;"><i class='bx bx-money'></i></a>
                                    </li>
                                    <li class="breadcrumb-item active" aria-current="page">venta</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <!--end breadcrumb-->
                    <div class="card">
                        <div class="card-body">
                            <div class="d-lg-flex align-items-center mb-4 gap-5">
                                <div class="position-relative d-flex justify-content-between align-items-center">
                                    <input type="text" class="form-control ps-5 radius-30" v-model="buscarVenta" placeholder="Buscar Venta"> <span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-search"></i></span>
                                    <button @click="buscarVentaFiltro()" class="btn btn-success radius-10 mt-2 mt-lg-0 m-2 w-100">Buscar</button>
                                    <button @click="buscarVenta = ''; buscarVentaFiltro()" class="btn btn-light radius-10 mt-2 mt-lg-0 m-2 w-100">Ver Todo</button>
                                    <button href="javascript:;" @click="clickButton"
                                        :class="buttonClass"  class="btn radius-15 mt-2 mt-lg-0 m-2 w-100">Ventas al Crédito</button>
                                </div>
                                <div v-if="userData && userData[0] && !userData[0].caja_del_dia" class="ms-auto">
                                    <span class="badge bg-warning text-dark"><i  class='bx bxs-lock-alt'></i>No Has Aperturado la Caja</span>
                                </div>
                                <div v-show="userData && userData[0] && userData[0].caja_del_dia" class="ms-auto">
                                    <add_venta ref="child_add"></add_venta>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table mb-0">
                                    <thead class="table-light">
                                        <tr>
                                            <th><h6>#Número</h6></th>
                                            <th><h6>Fecha</h6></th>
                                            <th><h6>Nit</h6></th>
                                            <th><h6>Nombre</h6></th>
                                            <th><h6>Estado</h6></th>
                                            <th><h6>Ver Detalle</h6></th>
                                            <th><h6>Total</h6></th>
                                            <th><h6>Forma de Pago</h6></th>
                                            <th><h6>Usuario</h6></th>
                                            <th><h6>Acción</h6></th>
                                            <th v-show="userData && userData[0] && userData[0].permiso_facturar"><h6>Facturación</h6></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in ventasDataFilter" :key="item.id">
                                            <td >
                                                <div class="d-flex align-items-center">
                                                    <div>
                                                        <input class="form-check-input me-3" type="checkbox" value="" aria-label="...">
                                                    </div>
                                                    <div class="ms-2">
                                                        <h6 class="mb-0 font-14">#{{item.numero}}</h6>
                                                    </div>
                                                </div>
                                            </td>
                                            <td><h6>{{item.fecha_creacion}}</h6></td>
                                            <td v-if="item.nombre_cliente"><h6>{{item.nombre_cliente}}</h6></td>
                                            <td v-if="!item.nombre_cliente"><h6>Consumidor Final</h6></td>
                                            <td><h6>{{item.nombre}}</h6></td>
                                            <td>
                                                    <button type="button"  v-if="[[item.estado]] == 'A'" class="btn btn-common btn-secondary px-5 radius-30">Abierto</button>
                                                    <button type="button"  v-if="[[item.estado]] == '9'" class="btn btn-common btn-danger px-5 radius-30">Anulado</button>
                                                    <button type="button"  v-if="[[item.estado]] == 'F'" class="btn btn-common btn-success px-5 radius-30">Finalizado</button>
                                                    <button type="button"  v-if="[[item.estado]] == 'P'" class="btn btn-common btn-primary px-5 radius-30">Proforma</button>
                                            </td>
                                            <td>
                                                <div v-if="item.detalle_venta.length >0">
                                                    <button @click="setVerDetalle(item.detalle_venta);" type="button" data-bs-toggle="modal" data-bs-target="#detalleVenta" class="btn btn-common btn-light position-relative me-lg-5"> <i class='bx bx-comment-detail align-middle'></i> Ver Detalle <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{{item.detalle_venta.length}}</span>
                                                    </button>
                                                </div>
                                            </td>
                                            <td><h6>
                                                {{ $filters.currencyGTQ(item.total)}}</h6>
                                            </td>
                                            <td>
                                                <button v-if="item.estado == 'F'" type="button" class="btn btn-common btn-info position-relative me-lg-5"> <i v-if="item.tipo_pago == 'EFECTIVO'" class='bx bx-money align-middle'></i> <i v-else-if="item.tipo_pago == 'DEPOSITO'" class='bx bx-transfer align-middle'></i> <i v-else class='bx bx-credit-card align-middle'></i> {{item.tipo_pago}}<i v-if="item.venta_credito_pagado"  class='bx bxs-badge-check align-middle'></i>
                                                </button>
                                            </td>
                                            <td><h6>{{item.nombre_usuario}}</h6></td>
                                            <td>
                                                <div class="d-flex order-actions">
                                                    <a v-if="[[item.estado]] == 'P' || [[item.estado]] == 'A'  && userData && userData[0] && userData[0].caja_del_dia" :href="this.URL_TENANT+'/venta/detalle_venta/'+item.id" class="ms-3"><i  class='bx bxs-edit'></i></a>
                                                    <a v-if="[[item.estado]] == 'P'" target="_blank" :href="this.URL_TENANT+'/venta/generar_impresion_venta/'+item.id+'/P/'" class="ms-3"><i  class='bx bx-printer'></i></a>
                                                    <a v-if="[[item.estado]] == 'P'" target="_blank" :href="this.URL_TENANT+'/venta/generar_impresion_venta_descarga/'+item.id+'/P/'" class="ms-3"><i  class='bx bx-download'></i></a>
                                                    <a v-if="[[item.estado]] == 'A' && userData && userData[0] && !userData[0].caja_del_dia" class="ms-3"><i  class='bx bxs-lock-alt'></i></a>
                                                    <a v-if="[[item.estado]] == 'F'" target="_blank" :href="this.URL_TENANT+'/venta/generar_impresion_venta/'+item.id+'/V/'" class="ms-3"><i class='bx bx-printer'></i></a>
                                                    <a v-if="[[item.estado]] == 'F'" target="_blank" :href="this.URL_TENANT+'/venta/generar_impresion_venta_descarga/'+item.id+'/V/'" class="ms-3"><i class='bx bx-download'></i></a>
                                                    <a v-if="[[item.estado]] == 'F'" target="_blank" :href="this.URL_TENANT+'/venta/generar_ticket_88mm/'+item.id+'/V/'" class="ms-3"><i class='bx bx-receipt'></i></a>
                                                </div>
                                                <div class="d-flex order-actions">
                                                    
                                                </div>
                                            </td>
                                            <td v-show="userData && userData[0] && userData[0].permiso_facturar">
                                                <div class="d-flex order-actions">
                                                    <a v-if="item.tiene_factura" target="_blank" :href="this.URL_TENANT+'/venta/generar_impresion_factura/'+item.id_factura+'/'" :style="item.tiene_anulacion ? { color: '#841e1e'}: {}"  class="ms-3"><i  class='bx bx-printer'></i></a>
                                                    <a v-if="item.tiene_factura" target="_blank" :href="this.URL_TENANT+'/venta/generar_ticket_88mm_factura/'+item.id_factura+'/'" :style="item.tiene_anulacion ? { color: '#841e1e'}: {}"  class="ms-3"><i  class='bx bx-receipt'></i></a>
                                                </div>
                                                <button v-if="!item.tiene_factura && [[item.estado]] == 'F' && item.tipo_pago !='CREDITO'" type="button" @click="paraFacturar(item, 'FACT');" data-bs-toggle="modal"  data-bs-target="#facturar" class="btn btn-common btn-light position-relative me-lg-5">
                                                    <i  class='bx bx-receipt align-middle'></i> Facturar
                                                </button>
                                                <button v-if="!item.tiene_factura && [[item.estado]] == 'F' && item.tipo_pago =='CREDITO'" type="button" @click="paraFacturar(item, 'FCAM');" data-bs-toggle="modal"  data-bs-target="#facturar" class="btn btn-common btn-light position-relative me-lg-5">
                                                    <i  class='bx bx-receipt align-middle'></i> Fact Cambiaria
                                                </button>
                                            </td>
                                            
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- Paginacion -->
                        <div class="card-body">
                            <nav aria-label="Page navigation example">
                                    <ul class="pagination">
                                        <li class="page-item">
                                            <a class="page-link" href="javascript:;" @click="cambiarUrl(ventasData.links.previous, 'atras')">Atrás</a>
                                        </li>
                                        <li class="page-item" :class="{active: indexPagination == index}" v-for="index in ventasData.total_pages" :key="index">
                                            <a class="page-link"  href="javascript:;javascript:;" @click="cambiarUrl(this.URL_TENANT+'/api/venta/?page='+index+'', index)">{{ index }}</a>
                                        </li>
                                        <li class="page-item"><a class="page-link" href="javascript:;" @click="cambiarUrl(ventasData.links.next, 'adelante')">Siguiente</a>
                                        </li>
                                    </ul>
                            </nav>
                        </div>
                        <!-- Fin Paginacion -->
                    </div>

                </div>
            </div>
<!-- Modal Ver Detalle -->
<div class="modal fade" id="detalleVenta" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Detalle de Venta</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="card">
                    <div class="card-body">
                        <table class="table mb-0">
                            <thead class="table-light">
                                <tr>
                                    <th><h6>Producto</h6></th>
                                    <th><h6>Cantidad</h6></th>
                                    <th><h6>Unidad Medida</h6></th>
                                    <th><h6>Precio</h6></th>
                                    <th><h6>Sub Total</h6></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item2 in verDetalleVenta" :key="item2.id">
                                    <td><h6>{{item2.nombre_producto}}</h6></td>
                                    <td><h6>{{item2.cantidad}}</h6></td>
                                    <td><h6>{{item2.nombre_unidad_medida}}</h6></td>
                                    <td><h6>{{ $filters.currencyGTQ(item2.precio) }}</h6></td>
                                    <td><h6>{{ $filters.currencyGTQ(item2.cantidad * item2.precio)}} </h6></td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
</div>
<!-- FIN Modal Ver Detalle -->
<!-- Modal Facturar -->
<div class="modal fade" id="facturar" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Crear Factura</h5>
                <button type="button" class="btn-close"  id="facturaModal" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <facturar :tipo="tipoFactura"  :datos_venta="ventaParaFactura" @factura-creada="cerrarModal" ref="child_facturar"></facturar>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                <button type="button" class="btn btn-primary" :disabled="$refs.child_facturar && $refs.child_facturar.facturando" @click="crearFactura">
                    <loading v-if="$refs.child_facturar && $refs.child_facturar.facturando"></loading>
                    Facturar
                </button>
            </div>
        </div>
    </div>
</div>
<!-- FIN Modal Facturar -->
    </template>
    <style>
    .btn-common {
        width: 100%;
        height: 100%;
        max-width: 175px; /* Ancho máximo para limitar el crecimiento excesivo en pantallas grandes */
        max-height: 50px; /* Altura máxima para limitar el crecimiento excesivo en pantallas grandes */
    }
    </style>
    <script>
    import  Cookies from 'js-cookie';
    import facturar from "./facturar.vue"

    import '@vuepic/vue-datepicker/dist/main.css';
    import add_venta from "./agregar_venta.vue"
    import loading from "../utils/loading.vue"

    //import { ref } from 'vue';
    console.log(Cookies.get());
    export default {
        name: 'ventas_component',
        components:{
            add_venta,
            facturar,
            loading,
        },
    setup() {

    },
    data() {
        return{
            //Globales:
            URL_TENANT: '',
            userData: {},
            url: "/api/venta/",
            indexPagination: 1,
            buscarVenta: '',
            filtroVenta: '',
            ventasData: [],
            verDetalleVenta: [],
            isClicked: false,
            ventaParaFactura: {},
            tipoFactura: '',
            empresa: {}

            }
        },

    computed: {
        buttonClass() {
            return this.isClicked ? 'btn-info' : 'btn-outline-light';
        },
        ventasDataFilter() {
            if(this.filtroVenta != ''){
                    return this.ventasData.results.filter((item) => {
                    return item.tipo_pago.toLowerCase().includes(this.filtroVenta.toLowerCase())})
                }
                else{
                    return this.ventasData.results;
                }
        },
    },
    watch:{
        url: function () {
            this.getVentasData();
        },
    },
    methods: {
        async getEmpresaData() {
            const response = await fetch(this.URL_TENANT+'/api/empresa/');
            this.empresa = await response.json();
        },
        cerrarModal(idFactura){
            console.log("CERRAR MODAL");
            //Aquí
        if(this.empresa[0].impresion_ticket == true){
            window.open(this.URL_TENANT+'/venta/generar_ticket_88mm_factura/'+idFactura+'/', '_blank');
        }else{
            window.open(this.URL_TENANT+'/venta/generar_impresion_factura/'+idFactura+'/', '_blank');
        }
            document.getElementById('facturaModal').click();
            this.getVentasData();
        },
        paraFacturar(item, tipo){
            this.ventaParaFactura = item;
            this.tipoFactura = tipo;
        },
        crearFactura() {
            this.$refs.child_facturar.crearFactura();
        },
        async buscarVentaFiltro(){
            if(this.buscarVenta==""){
                await this.getVentasData();
            }else{
                await this.getVentasDataFilter(this.buscarVenta);
            }
        },

        clickButton() {
            this.isClicked = !this.isClicked;
            this.filtroVentaSet(this.isClicked ?  'CREDITO': '');
        },
        async filtroVentaSet(item){
            console.log(item);
            this.filtroVenta = item;
        },
        // Llenar Ver Detalle:
        async setVerDetalle(item){
            this.verDetalleVenta = item;
        },
        //Paginación:
        async cambiarUrl(link, index){
            this.url = link;
            if (index === "atras" && link){
                this.indexPagination = this.indexPagination - 1;
            }
            else if (index === "adelante" && link != null){
                this.indexPagination = this.indexPagination + 1;
            }
            else if (link){
                this.indexPagination = index;
            }
        },
        // Llenado:
        async getVentasData() {
            const response = await fetch(this.url);
            this.ventasData = await response.json();
        },
        async getVentasDataFilter(value) {
            const response = await fetch(this.url+'?valor='+value);
            this.ventasData = await response.json();
        },
        async getUserData(){
		const response = await fetch(this.URL_TENANT+'/api/user/');
		this.userData = await response.json();
	},
        },
    mounted() {
        console.log("MOUNTED");
        this.$watch(
            "$refs.child_add.venta_creada",
            () => (this.getVentasData()),
        );
        this.$watch(
            "$refs.child_add.id_venta_creada",
            () => (  console.log("SE CREO LA VENTAS"),window.location.href = this.URL_TENANT+'/venta/detalle_venta/'+this.$refs.child_add.id_venta_creada),
        );
        },
created() {
          // Fetch tasks on page load
        console.log("CREATED");

        const result = window.location.origin;
        this.URL_TENANT = result;
        this.url = this.URL_TENANT + this.url
        this.getUserData();
        this.getVentasData();
        this.getEmpresaData();
        },
    }
    </script>