<template>
<div class="page-wrapper">
            <div class="page-content">
                <!--breadcrumb-->
                <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                    <div class="breadcrumb-title pe-3">Venta</div>
                    <div class="ps-3">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb mb-0 p-0">
                                <li class="breadcrumb-item"><a href="javascript:;"><i class='bx bx-money'></i></a>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">Detalle de Venta</li>
                            </ol>
                        </nav>
                    </div>
                    <div class="ms-auto">
						<div class="btn-group">
							<a href="/venta/venta/" type="button" class="btn btn-light">Regresar a Ventas</a>
						</div>
					</div>
                </div>
                <!--end breadcrumb-->
                    <div class="main-body">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="d-flex flex-column align-items-center text-center">
                                            <div class="mt-3">
                                                <h4># Venta: {{ ventaData.numero }}</h4>
                                                <h4>Total: {{  $filters.currencyGTQ(ventaData.total) }}</h4>
                                            </div>
                                        </div>
                                        <hr class="my-4" />
                                        <ul class="list-group list-group-flush" >
                                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                <h6 class="mb-0"><i class="bx bx-id-card">&nbsp;</i>Nit</h6>
                                                <span class="text-white">{{ ventaData.nit }}</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                <h6 class="mb-0"><i class="bx bx-user">&nbsp;</i>Nombre</h6>
                                                <span class="text-white">{{ ventaData.nombre }}</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                <h6 class="mb-0"><i class="bx bx-map">&nbsp;</i>Dirección</h6>
                                                <span class="text-white">{{ ventaData.direccion }}</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                <h6 class="mb-0"><i class="bx bx-radio-circle">&nbsp;</i>Estado</h6>
                                                <span class="text-white">{{ ventaData.nombre_estado }}</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                <h6 class="mb-0"><i class="bx bx-calendar">&nbsp;</i>Fecha</h6>
                                                <span class="text-white">{{ ventaData.fecha_creacion }}</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                <h6 class="mb-0"><i class="lni lni-money-location">&nbsp;</i>Forma de Pago</h6>
                                                <span class="text-white">{{ ventaData.tipo_pago }}</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                <h6 class="mb-0"><i class="bx bx-user-pin">&nbsp;</i>Usuario</h6>
                                                <span class="text-white">{{ ventaData.nombre_usuario }}</span>
                                            </li>
                                        </ul>
                                        <div class="d-flex flex-column align-items-center text-center">
                                            <div class="mt-3">
                                                <button type="button" ref="finalizarVentaBtn" :disabled="!detalleVentaData.length > 0 || ventaData.estado =='F'" @click="abrirFinalizarVenta(); getUserData(); getProductosBusquedaData()" class="btn btn-success px-5">Finalizar Venta (F12)</button>
                                                <!-- Modal Finalizar Venta -->
                                                <div class="modal fade"  id="finalizarDetalleVenta" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div class="modal-dialog modal-lg">
                                                        <div class="modal-content bg-dark">
                                                            <div class="modal-header">
                                                                <h5 class="modal-title" id="exampleModalLabel">Finalizar Venta</h5>
                                                                <button type="button" class="btn-close" id="agregar_detalle_close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div class="modal-body">
                                                                <div class="row mb-3">
                                                                    <div class="col-sm-2">
                                                                        <h6 class="mb-0">NIT</h6>
                                                                    </div>
                                                                    <div class="col-sm-10">
                                                                        <input ref="inputInModal" type="text" id="nit" readonly class="form-control" v-model="ventaData.nit"  />
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-3">
                                                                    <div class="col-sm-2">
                                                                        <h6 class="mb-0">Nombre</h6>
                                                                    </div>
                                                                    <div class="col-sm-10">
                                                                        <input type="text" id="nombre" readonly class="form-control" v-model="ventaData.nombre"  />
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-3">
                                                                    <div class="col-sm-2">
                                                                        <h6 class="mb-0">Dirección</h6>
                                                                    </div>
                                                                    <div class="col-sm-10">
                                                                        <input type="text" id="direccion" readonly class="form-control" v-model="ventaData.direccion"  />
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-3">
                                                                    <div class="col-sm-2">
                                                                        <h6 class="mb-0">SubTotal</h6>
                                                                    </div>
                                                                    <div class="col-sm-10">
                                                                        <input type="text" id="total" readonly class="form-control" :value="subtotalFormateado"  />
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-3">
                                                                    <div class="col-sm-2">
                                                                        <h6 class="mb-0">Descuento</h6>
                                                                    </div>
                                                                    <div class="col-sm-10">
                                                                        <input type="number" id="descuento" ref="descuento" class="form-control" @input="calcularTotal" v-model="ventaData.descuento"  />
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-3">
                                                                    <div class="col-sm-2">
                                                                        <h6 class="mb-0">Total</h6>
                                                                    </div>
                                                                    <div class="col-sm-10">
                                                                        <input type="text" id="total" readonly class="form-control"  :value="totalFormateado"  />
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-3">
                                                                    <div class="col-sm-2">
                                                                        <h6 class="mb-0">Forma de Pago</h6>
                                                                    </div>
                                                                    <div class="col-sm-10">
                                                                        <select v-model="ventaData.tipo_pago" required class="form-select form-control mb-3" aria-label="Default select example">
                                                                            <option selected value="CONTADO">Contado</option>
                                                                            <option value="DEPOSITO">Depósito</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <hr>

                                                                <div class="row mb-3">
                                                                    <div class="col-sm-2">
                                                                        <h6 class="mb-0">Cantidad Recibida</h6>
                                                                    </div>
                                                                    <div class="col-sm-4">
                                                                        <input type="number" id="cantidad_recibida" ref="cantidad_recibida" class="form-control"  @input="calcularCambio" v-model="cantidad_recibida"  />
                                                                    </div>
                                                                    <div class="col-sm-2">
                                                                        <h6 class="mb-0">Cambio</h6>
                                                                    </div>
                                                                    <div class="col-sm-4">
                                                                        <input type="number" id="cambio" ref="cambio" class="form-control" readonly v-model="cambio"  />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="modal-footer">
                                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>

                                                                <button type="button" @click="finalizarVenta();"  :disabled="estaFinalizandoVenta" class="btn btn-success px-5">Finalizar</button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- End Modal Finalizar Venta -->
                                            </div>
                                            <div class="mt-3">
                                                <button type="button" :disabled="!detalleVentaData.length > 0 || ventaData.estado =='F'" @click="abrirModalVentaCredito(); getPlazoCredito(); getUserData();" class="btn btn-info px-5">Venta al Crédito</button>
                                                <!-- Modal PROFORMA Venta -->
                                                <div class="modal fade"  id="facturaCredito" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div class="modal-dialog modal-lg">
                                                        <div class="modal-content bg-dark">
                                                            <div class="modal-header">
                                                                <h5 class="modal-title" id="exampleModalLabel">Crear Venta al Crédito</h5>
                                                                <button type="button" class="btn-close" id="agregar_detalle_close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div class="modal-body">
                                                                <div class="row mb-3">
                                                                    <div class="col-sm-2">
                                                                        <h6 class="mb-0">NIT</h6>
                                                                    </div>
                                                                    <div class="col-sm-10">
                                                                        <input ref="inputInModal" type="text" id="nit" readonly class="form-control" v-model="ventaData.nit"  />
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-3">
                                                                    <div class="col-sm-2">
                                                                        <h6 class="mb-0">Nombre</h6>
                                                                    </div>
                                                                    <div class="col-sm-10">
                                                                        <input type="text" id="nombre" readonly class="form-control" v-model="ventaData.nombre"  />
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-3">
                                                                    <div class="col-sm-2">
                                                                        <h6 class="mb-0">Dirección</h6>
                                                                    </div>
                                                                    <div class="col-sm-10">
                                                                        <input type="text" id="direccion" readonly class="form-control" v-model="ventaData.direccion"  />
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-3">
                                                                    <div class="col-sm-2">
                                                                        <h6 class="mb-0">SubTotal</h6>
                                                                    </div>
                                                                    <div class="col-sm-10">
                                                                        <input type="text" id="total" readonly class="form-control" :value="subtotalFormateado"  />
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-3">
                                                                    <div class="col-sm-2">
                                                                        <h6 class="mb-0">Descuento</h6>
                                                                    </div>
                                                                    <div class="col-sm-10">
                                                                        <input type="number" id="descuento" ref="descuento" class="form-control" @input="calcularTotal" v-model="ventaData.descuento"  />
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-3">
                                                                    <div class="col-sm-2">
                                                                        <h6 class="mb-0">Total</h6>
                                                                    </div>
                                                                    <div class="col-sm-10">
                                                                        <input type="text" id="total" readonly class="form-control"  :value="totalFormateado"  />
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-3">
                                                                    <div class="col-sm-2">
                                                                        <h6 class="mb-0">Plazo</h6>
                                                                    </div>
                                                                    <div class="col-sm-10">
                                                                        <vue-select  :options="plazoCredito"
                                                                            @blur="removeObjectArray(plazosData, 'id')"
                                                                            v-model="plazosData.id"
                                                                            label-by="dias"
                                                                            track-by="dias"
                                                                            value-by="id"
                                                                            search-placeholder="Selecciona el Plazo"
                                                                            multiple
                                                                            collapse-tags="true"
                                                                            taggable
                                                                            searchable
                                                                            placeholder="Selecciona el Plazo"
                                                                            close-on-select>
                                                                            <template #label="{ selected }">
                                                                            <template v-if="selected">
                                                                                <div style="color:white;">{{ selected.dias }} </div>
                                                                            </template>
                                                                            <template v-else > <div style="color:white;">Seleccione el Plazo</div></template>
                                                                            </template>
                                                                            <template #dropdown-item="{ option }">
                                                                            <div style="color:white;">{{ option.dias }} días</div>
                                                                            </template>
                                                                        </vue-select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="modal-footer">
                                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                                                <button type="button" @click="finalizarVentaCredito();"  class="btn btn-success px-5">Crear Venta al Crédito</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- End Modal PROFORMA Venta -->
                                            </div>
                                            <div class="mt-3">
                                                <button type="button" @click="abrirModalProforma()" :disabled="!detalleVentaData.length > 0 || ventaData.estado =='F'" class="btn btn-primary px-5">Factura Proforma</button>
                                                <!-- Modal PROFORMA Venta -->
                                                <div class="modal fade"  id="facturaProforma" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div class="modal-dialog modal-lg">
                                                        <div class="modal-content bg-dark">
                                                            <div class="modal-header">
                                                                <h5 class="modal-title" id="exampleModalLabel">Crear Factura Proforma</h5>
                                                                <button type="button" class="btn-close" id="agregar_detalle_close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div class="modal-body">
                                                                <div class="row mb-3">
                                                                    <div class="col-sm-2">
                                                                        <h6 class="mb-0">NIT</h6>
                                                                    </div>
                                                                    <div class="col-sm-10">
                                                                        <input ref="inputInModal" type="text" id="nit" readonly class="form-control" v-model="ventaData.nit"  />
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-3">
                                                                    <div class="col-sm-2">
                                                                        <h6 class="mb-0">Nombre</h6>
                                                                    </div>
                                                                    <div class="col-sm-10">
                                                                        <input type="text" id="nombre" readonly class="form-control" v-model="ventaData.nombre"  />
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-3">
                                                                    <div class="col-sm-2">
                                                                        <h6 class="mb-0">Dirección</h6>
                                                                    </div>
                                                                    <div class="col-sm-10">
                                                                        <input type="text" id="direccion" readonly class="form-control" v-model="ventaData.direccion"  />
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-3">
                                                                    <div class="col-sm-2">
                                                                        <h6 class="mb-0">SubTotal</h6>
                                                                    </div>
                                                                    <div class="col-sm-10">
                                                                        <input type="text" id="total" readonly class="form-control" :value="subtotalFormateado"  />
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-3">
                                                                    <div class="col-sm-2">
                                                                        <h6 class="mb-0">Descuento</h6>
                                                                    </div>
                                                                    <div class="col-sm-10">
                                                                        <input type="number" id="descuento" ref="descuento" class="form-control" @input="calcularTotal" v-model="ventaData.descuento"  />
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-3">
                                                                    <div class="col-sm-2">
                                                                        <h6 class="mb-0">Total</h6>
                                                                    </div>
                                                                    <div class="col-sm-10">
                                                                        <input type="text" id="total" readonly class="form-control"  :value="totalFormateado"  />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="modal-footer">
                                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                                                <button type="button" @click="facturaProforma();"  class="btn btn-success px-5">Crear Factura Proforma</button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- End Modal PROFORMA Venta -->
                                            </div>
                                            <div class="mt-3">
                                                <button type="button" :disabled="ventaData.estado =='F'" @click="abrirModalAnular()" class="btn btn-danger px-5">Anular Venta</button>
                                                <!-- Modal Anular Venta -->
                                                <div class="modal fade"  id="anularVenta" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div class="modal-dialog modal-lg">
                                                        <div class="modal-content bg-dark">
                                                            <div class="modal-header">
                                                                <h5 class="modal-title" id="exampleModalLabel">Anular Venta</h5>
                                                                <button type="button" class="btn-close" id="agregar_detalle_close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div class="modal-body">
                                                                <div class="row mb-3">
                                                                    <h5 class="mb-1">¿Desea Anular la Venta {{ ventaData.numero }}?</h5>
                                                                </div>
                                                            </div>
                                                            <div class="modal-footer">
                                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>

                                                                <button type="button" @click="anularVenta();"  class="btn btn-danger px-5">Anular Venta</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- End Modal Anular Venta -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-8">
                                <div class="card">
                                    <div class="card-body p-4">
                                        <div class="row">
                                            <div class="col-lg-9">
                                                <h3>Detalle de Venta</h3>
                                            </div>
                                            <div class="col-lg-3">
                                                <button type="button" ref="buscarProductoBtn"  @click="abrirModalBuscarProducto()" class="btn btn-light">Buscar Producto (F1)</button>
                                                <!-- Modal -->
                                                <div class="modal fade" id="agregarDetalleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div class="modal-dialog modal-lg">
                                                        <div class="modal-content bg-dark">
                                                            <div class="modal-header">
                                                                <h5 class="modal-title" id="exampleModalLabel">Añadir Producto a la Venta</h5>
                                                                <button type="button" class="btn-close" id="agregar_detalle_close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div class="modal-body">
                                                                <div class="row">
                                                                    <div class="col-lg-10">
                                                                        <input ref="busquedaPorTexto" type="text" class="form-control ps-5" v-model="busqueda" @keydown.enter="getProductosBusquedaData()" autofocus  placeholder="Buscar Producto...(Por Nombre o Código de Barra)"> <span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-search"></i></span>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <button type="button"   @click="getProductosBusquedaData()"  class="btn btn-light">Buscar</button>
                                                                    </div>
                                                                </div>
                                                                <hr/>
                                                                <table class="table mb-0">
                                                                    <thead class="table-light">
                                                                        <tr>
                                                                            <th><h6>Código</h6></th>
                                                                            <th><h6>Código de Barra</h6></th>
                                                                            <th><h6>Producto</h6></th>
                                                                            <th><h6>Unidades de Medida</h6></th>
                                                                            <th><h6>Seleccionar</h6></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-for="itemProd in productosBusqueda.results" :key="itemProd.codigo">
                                                                            <td><h6>{{ itemProd.codigo }}</h6></td>
                                                                            <td><h6>
                                                                                <div v-for='cb in itemProd.codigos_barra_producto' :key="cb.id" class="col">
                                                                                    <ul>
                                                                                        <li>
                                                                                        {{cb.codigo_barra}}
                                                                                        </li>
                                                                                    </ul>
                                                                                </div></h6>
                                                                            </td>
                                                                            <td><h6>
                                                                                <label>{{itemProd.nombre}}</label></h6>
                                                                            </td>
                                                                            <td><h6>
                                                                                <div v-for='um in itemProd.unidades_medida_producto' :key="um.id" class="col">
                                                                                    <ul>
                                                                                        <li>
                                                                                        {{um.nombre_unidad_medida}}
                                                                                        </li>
                                                                                    </ul>
                                                                                </div></h6>
                                                                            </td>
                                                                            <td><h6>
                                                                                <button type="button" data-bs-dismiss="modal"  @click="buscarProductoPorBarra(itemProd.id, 'id')"  class="btn btn-light">Seleccionar</button>
                                                                            </h6></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="modal-footer">
                                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- End Modal -->
                                            </div><!-- End Col-2 --> 
                                        </div><!-- End Row -->
                                        <div class="row">
                                            <hr/>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <input type="text" ref="busquedaPorBarra" v-model="barraBusqueda" autofocus class="form-control ps-5" @keyup.enter="buscarProductoPorBarra(barraBusqueda, 'barra');" placeholder="Código del Producto | Código de Barra">
                                            </div>
                                            <div class="col-lg-6">
                                                <button type="button" @click="buscarProductoPorBarra(barraBusqueda, 'barra')" class="btn btn-light">Ir</button>
                                            </div>
                                        </div>
                                        <div class="form-body mt-4">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="border border-3 p-4 rounded">
                                                        <div class="modal-body">
                                                            <table class="table mb-0">
                                                                <thead class="table-light">
                                                                    <tr>
                                                                        <th><h6>Producto</h6></th>
                                                                        <th><h6>Cantidad</h6></th>
                                                                        <th><h6>Unidad Medida</h6></th>
                                                                        <th><h6>Precio</h6></th>
                                                                        <th><h6>Subtotal</h6></th>
                                                                        <th><h6>Acciones</h6></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="item in detalleVentaData" :key="item.id">
                                                                        <td><h6>{{ item.nombre_producto }}</h6></td>
                                                                        <td><h6>{{ item.cantidad }}</h6></td>
                                                                        <td><h6>{{ item.nombre_unidad_medida }}</h6></td>
                                                                        <td><h6>{{ $filters.currencyGTQ(item.precio) }}</h6></td>
                                                                        <td><h6>{{ $filters.currencyGTQ(item.precio * item.cantidad)   }}</h6></td>
                                                                        <td><h6>
                                                                            <div class="d-flex order-actions">
                                                                                <a href="javascript:;"  @click="modificarCantidadProductos(item.id, item.cantidad, item.venta, item.producto, item.nombre_producto, item.precio)" class="ms-3"><i class='bx bxs-edit'></i></a>
                                                                                <a href="javascript:;" @click="eliminarProductoModal(item.id, item.cantidad, item.venta, item.producto, item.nombre_producto, item.precio)" class="ms-3"><i class='bx bxs-trash'></i></a>
                                                                            </div></h6>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div><!--end row-->
                                        </div>
                                    </div><!-- End Card Body --> 
                                </div><!-- End Card -->
                            </div><!-- End Col-8 --> 
                        </div><!-- End Row -->
                    </div>
                </div>
            </div>
<!-- Modal Agregar Producto-->
<div class="modal fade" id="modalAgregarProducto" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content bg-dark">
        <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalToggleLabel2">Producto</h1>
            <button type="button" class="btn-close" id="agregar_detalle_close2" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div class="row mb-3">
                <div class="col-sm-2">
                    <h6 class="mb-0">Código</h6>
                </div>
                <div class="col-sm-10">
                    <input type="text"  id="producto"  class="form-control" v-model="productoEncontrado.codigo"  readonly/>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-2">
                    <h6 class="mb-0">Producto</h6>
                </div>
                <div class="col-sm-10">
                    <input type="text"  id="producto"  class="form-control" v-model="productoEncontrado.nombre"  readonly/>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-2">
                    <h6 class="mb-0">Unidad Medida</h6>
                </div>
                <div class="col-sm-10">
                    <vue-select  :options="productoEncontrado.unidades_medida"
                        @blur="removeObjectArray(productoEncontrado, 'unidad_medida')"
                        v-model="productoEncontrado.unidad_medida"
                        label-by="nombre_unidad_medida"
                        track-by="nombre_unidad_medida"
                        value-by="id"
                        search-placeholder="Selecciona una Unidad de Medida"
                        multiple
                        collapse-tags="true"
                        taggable
                        searchable
                        placeholder="Selecciona una Unidad de Medida"
                        close-on-select>
                        <template #label="{ selected }">
                        <template v-if="selected">
                            <div style="color:white;">{{ selected.nombre_unidad_medida }} </div>
                        </template>
                        <template v-else > <div style="color:white;">Seleccione una Unidad de Medida</div></template>
                        </template>
                        <template #dropdown-item="{ option }">
                        <div style="color:white;">{{ option.nombre_unidad_medida }}</div>
                        </template>
                    </vue-select>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-2">
                    <h6 class="mb-0">Cantidad</h6>
                </div>
                <div class="col-sm-10">
                    <input type="text"  id="cantidad"  class="form-control" v-model="productoEncontrado.cantidad" />
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-2">
                    <h6 class="mb-0">Precio</h6>
                </div>
                <div class="col-sm-10">
                    <input type="text" id="precio"  class="form-control" v-model="productoEncontrado.precio" />
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-2">
                    <h6 class="mb-0">Inventario</h6>
                </div>
                <div class="col-sm-10">
                    <input type="text" readonly  id="precio"  class="form-control" v-model="productoEncontrado.inventario_um" />
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>

            <!--<button class="btn btn-primary"  :disabled="agregando" @click="agregarProductoDetalle();">Agregar</button>-->
            <button class="btn btn-primary" v-if="productoEncontrado.cantidad <= productoEncontrado.inventario_um && productoEncontrado.inventario_um != 0" :disabled="agregando" @click="agregarProductoDetalle();">Agregar</button>
            <button class="btn btn-primary" v-if="productoEncontrado.cantidad > productoEncontrado.inventario_um || productoEncontrado.inventario_um == 0">No Hay Suficiente Inventario en el Sistema</button>
        </div>
        </div>
    </div>
</div>
<!-- Fin Modal Agregar Producto -->
<!-- Modal Modificar Producto -->
<div class="modal fade"  id="modificarDetalleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content bg-dark">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Modificar Producto</h5>
                <button type="button" class="btn-close" id="modificar_detalle_close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row mb-3">
                    <div class="col-sm-2">
                        <h6 class="mb-0">Producto</h6>
                    </div>
                    <div class="col-sm-10">
                        <input class="form-control" readonly v-model="productoDetalleModificar.nombre_producto" type="text" >
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-sm-2">
                        <h6 class="mb-0">Cantidad</h6>
                    </div>
                    <div class="col-sm-10">
                    <input class="form-control" ref="cantidadModificarProducto" v-model="productoDetalleModificar.cantidad" type="number" placeholder="Cantidad de Unidades">
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-sm-2">
                        <h6 class="mb-0">Precio</h6>
                    </div>
                    <div class="col-sm-10">
                        <input type="text" id="precio"  class="form-control" v-model="productoDetalleModificar.precio" />
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                <button type="button" @click="modificarDetalleVenta()" data-bs-dismiss="modal" class="btn btn-warning">Modificar</button>
            </div>
        </div>
    </div>
    </div>
    <!-- End Modal Modificar Producto -->
    <!-- Modal Eliminar Producto -->
<div class="modal fade"  id="eliminarDetalleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content bg-dark">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Eliminar Producto</h5>
                <button type="button" class="btn-close" id="modificar_detalle_close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row mb-3">
                    <div class="col-sm-2">
                        <h6 class="mb-0">Producto</h6>
                    </div>
                    <div class="col-sm-10">
                        <input class="form-control" readonly v-model="productoDetalleModificar.nombre_producto" type="text" >
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                <button type="button" @click="eliminarDetalleVenta()" data-bs-dismiss="modal" class="btn btn-danger">Eliminar</button>
            </div>
        </div>
    </div>
    </div>
    <!-- End Modal Eliminar Producto -->
</template>

<script>
import  Cookies from 'js-cookie';
import $ from 'jquery';
let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js');
//import * as mykey from '../assets/js/mykey.js'
import { Modal } from 'bootstrap';
import VueNextSelect from 'vue-next-select';

console.log(Cookies.get());
export default {
    name: 'PerfilComponent',
    components:{
        'vue-select': VueNextSelect,
        },
data() {
	return{
        empresa: {},
        estaFinalizandoVenta: false,
        cantidad_recibida: '',
        cambio: '',
        barraBusqueda: '',
        busqueda: '',
        id_venta: '',
        agregando: false,
        id: '',
        userData: {},
		ventaData: [],
		plazosData: {id: ''},
        nameError: [],
        plazoCredito: [],
        detalleVentaData: [],
        productoDetalleModificar: {
            id: '',
            cantidad: '',
        },
        productosBusqueda: [],
        productoEncontrado: {
            codigo: '',
            cantidad: '',
            precio: '',
            producto: '',
            nombre: '',
            unidades_medida: [],
            unidad_medida: [],
            inventario: '',
            inventario_um: ''
        },
        productoSeleccionado: '',
		URL_TENANT: '',
        mostrarModalNuevo: false,
		}
	},
watch:{
    productoSeleccionado: {
            handler: function () {
                if(this.productoSeleccionado.length > 1){
                    this.productoSeleccionado.shift();
                }
            },
            deep: true,
        },
},
computed: {
    subtotalFormateado() {
      // Aplica el filtro currencyGTQ al valor de ventaData.total
        return this.$filters.currencyGTQ(this.ventaData.subtotal);
    },
    totalFormateado() {
      // Aplica el filtro currencyGTQ al valor de ventaData.total
        return this.$filters.currencyGTQ(this.ventaData.total);
    },
    cambioFormateado(){
        return this.$filters.currencyGTQ(this.cambio);
    },  
    productoDataFilter() {
        if (this.busqueda != ''){
            return this.productosBusqueda.results.filter((item) => {
                return  item.nombre.toLowerCase().includes(this.busqueda.toLowerCase()) || item.codigo.toLowerCase().includes(this.busqueda.toLowerCase()) || item.codigos_barra_producto.some(t => t.codigo_barra.toLowerCase().includes(this.busqueda.toLowerCase()))
        })
        }else{
            return [];
        }
    },
},


methods: {
    async cambiarPagina(page) {
        this.busqueda = ''; // Si no quieres que la búsqueda se aplique con cambio de página
        await this.getProductosBusquedaData(page);  // Cambiar la página
    },
    async abrirModalBuscarProducto(){
        this.myModal = new Modal(document.getElementById('agregarDetalleModal'), {
                backdrop: false,  // Permite cerrar al hacer clic afuera
                keyboard: true  // Permite cerrar con la tecla ESC
            });
        this.myModal.show();
        this.getProductosBusquedaData();
    },
    async getEmpresaData() {
        const response = await fetch(this.URL_TENANT+'/api/empresa/');
        this.empresa = await response.json();
    },
    // Para Venta al Crédito:
    async getPlazoCredito(){
        const response = await fetch(this.URL_TENANT+'/api/plazo_credito/');
		this.plazoCredito = await response.json();
    },
    async finalizarVentaCredito(){
        if (this.estaFinalizandoVenta) {
            return; // No hacer nada si ya hay una solicitud en curso
        }
        this.estaFinalizandoVenta = true; // Indicar que la solicitud está en curso
        const finaliza_status = await this.validarProductoInventario();
        console.log(finaliza_status);
        if(finaliza_status == false){
            return;
        }
        if(this.plazosData.id == '' || this.plazosData.id.length == 0){
            console.log("No Seleccionó Plazos");
            notificaciones.round_error_noti($.msg="No Seleccionó Plazos");
        }else{
        const formData = new FormData();
        formData.append("nit", this.ventaData.nit);
        formData.append("nombre", this.ventaData.nombre);
        formData.append("direccion", this.ventaData.direccion);
        formData.append("tipo_pago", 'CREDITO');
        formData.append("subtotal", this.ventaData.subtotal);
        formData.append("descuento", this.ventaData.descuento);
        formData.append("total", this.ventaData.total);
        formData.append("caja", this.userData[0].caja_del_dia.id);
        formData.append("cliente", this.ventaData.cliente != null ? this.ventaData.cliente : "");
        formData.append("estado", 'F');
        await fetch(this.URL_TENANT+'/api/venta/'+this.ventaData.id+'/',{
            method: 'PUT',
            headers: {
            'X-CSRFToken': Cookies.get('csrftoken'),
            },
            body: formData});
        for(let x=0; x<this.detalleVentaData.length; x++){
            const formData2 = new FormData();
            formData2.append("tipo_operacion", "VENTA");
            formData2.append("referencia", this.ventaData.id);
            formData2.append("producto", this.detalleVentaData[x].producto);
            formData2.append("cantidad", this.detalleVentaData[x].cantidad);
            formData2.append("observacion", "Salida por medio de una Venta");
            await fetch(this.URL_TENANT+'/api/ingreso_salida_producto/',{
                method: 'POST',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },
                body: formData2});
        }
        const formData3 = new FormData();
        formData3.append("venta", this.ventaData.id);
        formData3.append("plazo_credito", this.plazosData.id);
        await fetch(this.URL_TENANT+'/api/venta_credito/',{
            method: 'POST',
            headers: {
            'X-CSRFToken': Cookies.get('csrftoken'),
            },
            body: formData3});
        notificaciones.round_success_noti($.msg='Venta Finalizada');
        window.open('/venta/generar_impresion_venta/'+this.ventaData.id+'/V/', '_blank');
        setInterval(function(){location.href = '/venta/venta/'},1500);
        }
        this.estaFinalizandoVenta = false;
    },
    //Antes de Finalizar:
    async calcularTotal(){
        this.ventaData.total = this.ventaData.subtotal - this.ventaData.descuento;
    },
    async calcularCambio(){
        this.cambio = this.cantidad_recibida - this.ventaData.total;
    },
    // Factura Proforma:
    async facturaProforma(){
        const formData = new FormData();
        formData.append("nit", this.ventaData.nit);
        formData.append("nombre", this.ventaData.nombre);
        formData.append("direccion", this.ventaData.direccion);
        formData.append("tipo_pago", this.ventaData.tipo_pago);
        formData.append("subtotal", this.ventaData.subtotal);
        formData.append("descuento", this.ventaData.descuento);
        formData.append("total", this.ventaData.total);
        formData.append("cliente", this.ventaData.cliente != null ? this.ventaData.cliente : "");
        formData.append("estado", 'P');
        await fetch(this.URL_TENANT+'/api/venta/'+this.ventaData.id+'/',{
            method: 'PUT',
            headers: {
            'X-CSRFToken': Cookies.get('csrftoken'),
            },
            body: formData});
        notificaciones.round_success_noti($.msg='Factura Proforma Creada');
        window.open('/venta/generar_impresion_venta/'+this.ventaData.id+'/P/', '_blank');
        setInterval(function(){location.href = '/venta/venta/'},1500);

    },
    // Finalizar Venta:
    async validarProductoInventario(){
        console.log("Estoy en validar producto inventario");
        this.finaliza_status = true;
        return this.finaliza_status; // Se agregó para solventar problema de Dibeorsa
        /*for(let x=0; x<this.detalleVentaData.length; x++){
            this.obj = this.productosBusqueda.results.map(item =>
                item.unidades_medida_producto.find(t =>
                    t.id.toLowerCase().includes(this.detalleVentaData[x].producto.toLowerCase())
                )
            ).filter(t => t !== undefined);
            const inventario_actual = this.obj[0].producto_inventario / this.obj[0].cantidad_unidad_medida;
            console.log(this.obj);
            console.log(inventario_actual);
            if(this.detalleVentaData[x].cantidad > inventario_actual){
                notificaciones.round_error_noti($.msg='El Producto '+this.detalleVentaData[x].nombre_producto+' solo cuenta con '+inventario_actual+' de inventario... No se puede vender');
                this.finaliza_status = false;
            }
        }
        console.log("FINALIZA STATUS: "+this.finaliza_status)
        return this.finaliza_status;
        */
    },
    async abrirModalAnular(){
        this.myModal = new Modal(document.getElementById('anularVenta'), {
                backdrop: false,  // Permite cerrar al hacer clic afuera
                keyboard: true  // Permite cerrar con la tecla ESC
            });
        this.myModal.show();
    },
    async abrirModalProforma(){
        this.myModal = new Modal(document.getElementById('facturaProforma'), {
                backdrop: false,  // Permite cerrar al hacer clic afuera
                keyboard: true  // Permite cerrar con la tecla ESC
            });
        this.myModal.show();
    },
    async abrirModalVentaCredito(){
        this.myModal = new Modal(document.getElementById('facturaCredito'), {
                backdrop: false,  // Permite cerrar al hacer clic afuera
                keyboard: true  // Permite cerrar con la tecla ESC 
            });
        this.myModal.show();
    },
    async abrirFinalizarVenta(){
        this.myModal = new Modal(document.getElementById('finalizarDetalleVenta'), {
                backdrop: false,  // Permite cerrar al hacer clic afuera
                keyboard: true  // Permite cerrar con la tecla ESC
            });
        this.myModal.show();
    },
    async finalizarVenta(){
        if (this.estaFinalizandoVenta) {
            return; // No hacer nada si ya hay una solicitud en curso
        }
        this.estaFinalizandoVenta = true; // Indicar que la solicitud está en curso 
        const finaliza_status = await this.validarProductoInventario();
        console.log(finaliza_status);
        if(finaliza_status == false){
            return;
        }
        console.log("VENDIENDO");
        const formData = new FormData();
        formData.append("nit", this.ventaData.nit);
        formData.append("nombre", this.ventaData.nombre);
        formData.append("direccion", this.ventaData.direccion);
        formData.append("tipo_pago", this.ventaData.tipo_pago);
        formData.append("subtotal", this.ventaData.subtotal);
        formData.append("descuento", this.ventaData.descuento);
        formData.append("total", this.ventaData.total);
        formData.append("caja", this.userData[0].caja_del_dia.id);
        formData.append("cliente", this.ventaData.cliente != null ? this.ventaData.cliente : "");
        formData.append("estado", 'F');
        await fetch(this.URL_TENANT+'/api/venta/'+this.ventaData.id+'/',{
            method: 'PUT',
            headers: {
            'X-CSRFToken': Cookies.get('csrftoken'),
            },
            body: formData});
        for(let x=0; x<this.detalleVentaData.length; x++){
            const formData2 = new FormData();
            formData2.append("tipo_operacion", "VENTA");
            formData2.append("referencia", this.ventaData.id);
            formData2.append("producto", this.detalleVentaData[x].producto);
            formData2.append("cantidad", this.detalleVentaData[x].cantidad);
            formData2.append("observacion", "Salida por medio de una Venta");
            await fetch(this.URL_TENANT+'/api/ingreso_salida_producto/',{
                method: 'POST',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },
                body: formData2});
        }
        notificaciones.round_success_noti($.msg='Venta Finalizada');
        this.estaFinalizandoVenta = false;

        // Abrir el PDF en una nueva ventana para imprimir automáticamente
        if(this.empresa[0].impresion_ticket == true){
            window.open(`/venta/generar_ticket_88mm/${this.ventaData.id}/V/`, '_blank');
        }else{
            window.open('/venta/generar_impresion_venta/'+this.ventaData.id+'/V/', '_blank');
        }

        // Redirigir después de 1.5 segundos
        setTimeout(function(){
            location.href = '/venta/venta/';
        }, 1500);

    },
    // Anular Venta:
    async anularVenta(){
        const formData = new FormData();
        formData.append("nit", this.ventaData.nit);
        formData.append("nombre", this.ventaData.nombre);
        formData.append("direccion", this.ventaData.direccion);
        formData.append("tipo_pago", this.ventaData.tipo_pago);
        formData.append("total", this.ventaData.total);
        formData.append("cliente", this.ventaData.cliente != null ? this.ventaData.cliente : "");
        formData.append("estado", '9');
        await fetch(this.URL_TENANT+'/api/venta/'+this.ventaData.id+'/',{
            method: 'PUT',
            headers: {
            'X-CSRFToken': Cookies.get('csrftoken'),
            },
            body: formData});
        notificaciones.round_success_noti($.msg='Venta Anulada');
        //window.location.href = this.URL_TENANT+'/venta/venta/';
        setInterval(function(){location.href = '/venta/venta/'},1500);
    },
    // Modificar Cantidad de Productos:
    async eliminarProductoModal(_id, _cantidad, _venta, _producto, _nombre_producto, _precio){
        this.myModal = new Modal(document.getElementById('eliminarDetalleModal'), {
                backdrop: false,  // Permite cerrar al hacer clic afuera
                keyboard: true  // Permite cerrar con la tecla ESC
            });
        this.myModal.show();
        this.productoDetalleModificar = {
            id: _id,
            cantidad: _cantidad,
            venta: _venta,
            producto: _producto,
            nombre_producto: _nombre_producto,
            precio: _precio
        }
    },
    async modificarCantidadProductos(_id, _cantidad, _venta, _producto, _nombre_producto, _precio){
        this.myModal = new Modal(document.getElementById('modificarDetalleModal'), {
                backdrop: false,  // Permite cerrar al hacer clic afuera
                keyboard: true  // Permite cerrar con la tecla ESC
            });
        this.myModal.show();
        this.productoDetalleModificar = {
            id: _id,
            cantidad: _cantidad,
            venta: _venta,
            producto: _producto,
            nombre_producto: _nombre_producto,
            precio: _precio
        }
    },
    async modificarDetalleVenta(){
        const formData = new FormData();
        formData.append("id", this.productoDetalleModificar.id);
        formData.append("venta", this.productoDetalleModificar.venta);
        formData.append("producto", this.productoDetalleModificar.producto);
        formData.append("cantidad", this.productoDetalleModificar.cantidad);
        formData.append("precio", this.productoDetalleModificar.precio);
        await fetch(this.URL_TENANT+'/api/venta_detalle/'+this.productoDetalleModificar.id+'/',{
        method: 'PUT',
        headers: {
            'X-CSRFToken': Cookies.get('csrftoken'),
            },
        body: formData});
        notificaciones.round_success_noti($.msg='Cantidad Modificada Exitosamente');
        this.getDetalleVentaData();
        this.getVentaData();
    },
    async eliminarDetalleVenta(){
        const formData = new FormData();
        formData.append("id", this.productoDetalleModificar.id);
        //formData.append("venta", this.productoDetalleModificar.venta);
        //formData.append("producto", this.productoDetalleModificar.producto);
        //formData.append("cantidad", this.productoDetalleModificar.cantidad);
        await fetch(this.URL_TENANT+'/api/venta_detalle/'+this.productoDetalleModificar.id+'/',{
        method: 'DELETE',
        headers: {
            'X-CSRFToken': Cookies.get('csrftoken'),
            },
        body: formData});
        notificaciones.round_success_noti($.msg='Producto Eliminado Exitosamente');
        this.getDetalleVentaData();
        this.getVentaData();
    },
    // Validadores:
    validateInputs(){
        this.nameError =  (!this.productoEncontrado.unidad_medida || this.productoEncontrado.unidad_medida.length === 0) ? ['Debe Elegir Unidad de Medida'] : [];
        this.nameError = (!this.productoEncontrado.cantidad) ? [...this.nameError, 'Debe Ingresar la Cantidad'] : this.nameError;
    },
    // Agregar Productos al Detalle:
    async agregarProductoDetalle(){
        this.agregando = true;
        this.validateInputs();
        if(this.nameError.length != 0){
            console.log("NAME ERROR");
            this.nameError.forEach((element) =>{
                notificaciones.round_info_noti($.msg=element);
            });
        }else{
            const formData = new FormData();
            formData.append("venta", this.id_venta.id_venta);
            formData.append("cantidad", this.productoEncontrado.cantidad);
            formData.append("precio", this.productoEncontrado.precio);
            formData.append("producto", this.productoEncontrado.unidad_medida[0]);
            await fetch(this.URL_TENANT+'/api/venta_detalle/',{
                method: 'post',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },body: formData})
                notificaciones.round_success_noti($.msg='Producto Agregado Exitosamente');
                document.getElementById('agregar_detalle_close2').click();
                this.getDetalleVentaData();
                this.getVentaData();
        }
        this.agregando = false;
        this.busqueda = '';
        this.barraBusqueda = '';
        this.$refs.busquedaPorBarra.focus();
    },
    //Quitar Objeto:
    async removeObjectArray(array, atributo) {
        //console.log("REMOVE ARRAY");
        if (array[atributo].length > 1) {
            array[atributo].shift();
        }
        if(atributo == 'unidad_medida'){
            this.seleccionUnidadMedida();
        }
    },
    async seleccionUnidadMedida(){
        this.obj = this.productoEncontrado.unidades_medida.filter((item) => {
            return  item.id == this.productoEncontrado.unidad_medida[0];
        })
        console.log("OBJ::::");
        console.log(this.obj);
        this.productoEncontrado.unidad_medida[0] = this.obj[0]['id'];
        this.productoEncontrado.precio = this.obj[0]['precio_venta'];
        this.productoEncontrado.inventario = this.obj[0]['producto_inventario']  / this.obj[0]['cantidad_unidad_medida'];
        this.productoEncontrado.inventario_um = this.obj[0]['inventario'];
    },
    async cambiarUrl(link, index){
            this.url = link;
            if (index === "atras" && link){
                this.indexPagination = this.indexPagination - 1;
            }
            else if (index === "adelante" && link != null){
                this.indexPagination = this.indexPagination + 1;
            }
            else if (link){
                this.indexPagination = index;
            }
        },
    async mostrarRefs(){
        console.log("Mis Refs:");
        console.log(this.$refs);
        console.log(this.$refs.child);
        console.log(this.$refs.child.searchingInputValue);
    },
    async getVentaData() {
        console.log("LLAMANDO VENTA");
        console.log(this.id_venta);
        const response = await fetch(this.URL_TENANT+'/api/venta/'+this.id_venta.id_venta+'/');
        this.ventaData = await response.json();
    },
    async getDetalleVentaData() {
        console.log("LLAMANDO DETALLE VENTA");
        console.log(this.id_venta);
        const response = await fetch(this.URL_TENANT+'/api/venta_detalle/?id_venta='+this.id_venta.id_venta);
        this.detalleVentaData = await response.json();
    },
    async getProductosBusquedaData(page = 1) {
        console.log("LLAMANDO DETALLE VENTA");
        console.log(this.id_venta);
         // Incluir 'q' si hay una búsqueda activa
        const queryParams = new URLSearchParams({
            tipo: 'venta',
            q: this.busqueda,
            page: page,
        });
        const response = await fetch(`${this.URL_TENANT}/api/producto_busqueda_rapida/?${queryParams}`);
        this.productosBusqueda = await response.json();
    },
    //Buscar Unidades de Medida por  producto:
    async buscarUnidadesProductoData(id_producto, barra = ''){
        const response = await fetch(this.URL_TENANT+'/api/producto_unidades_medida_busqueda/?id_producto='+id_producto);
        this.productoEncontrado.unidades_medida = await response.json();
        console.log("UNIDADES MEDIDASSSSSSS....");
        console.log(barra);
        console.log(this.productoEncontrado.unidades_medida);
        const unidadMedidaMenor = this.productoEncontrado.unidades_medida.reduce((min, unidad) =>
            unidad.cantidad_unidad_medida < min.cantidad_unidad_medida ? unidad : min
        );
        if(unidadMedidaMenor){
            this.productoEncontrado.unidad_medida[0] = unidadMedidaMenor.id;
            this.productoEncontrado.precio = unidadMedidaMenor.precio_venta;
            this.productoEncontrado.inventario = unidadMedidaMenor.producto_inventario / unidadMedidaMenor.cantidad_unidad_medida;
            this.productoEncontrado.inventario_um = unidadMedidaMenor.inventario;

        }
        if(barra != ''){
            this.obj = this.productoEncontrado.unidades_medida.filter((item) => {
                return  item.codigo_barra == barra;
            })
            console.log("OBJ::::");
            console.log(this.obj);
            this.productoEncontrado.unidad_medida[0] = this.obj[0]['id'];
            this.productoEncontrado.precio = this.obj[0]['precio_venta'];
            this.productoEncontrado.inventario = this.obj[0]['producto_inventario'] / this.obj[0]['cantidad_unidad_medida'] ;
            this.productoEncontrado.inventario_um = this.obj[0]['inventario'];


        }
    },
    async limpiarProductoEncontrado(){
        this.productoEncontrado = {
            codigo: '',
            cantidad: '',
            precio: '',
            producto: '',
            nombre: '',
            unidades_medida: [],
            unidad_medida: [],
        }
    },
    // Buscar Producto por Barra:
    async buscarProductoPorBarra(dato, tipo){
        
        this.limpiarProductoEncontrado();
        if(tipo === 'barra'){
            this.busqueda = dato;
            await this.getProductosBusquedaData(1)
            console.log("barra.....");
            console.log(dato);
            try{
                this.obj = this.productosBusqueda.results.filter((item) => {
                    return  item.codigos_barra_producto.some(t => t.codigo_barra.toLowerCase().includes(dato.toLowerCase())) || item.codigo.toLowerCase().includes(dato.toLowerCase())
                })
            }catch(error){
                console.log("firulais");
                this.obj = [];
            }
            //let obj = this.productosBusqueda.results.find(item => item.codigos_barra_producto.some(t => t.codigo_barra.toLowerCase().includes(this.dato.toLowerCase())));
            console.log(this.obj);
            //console.log(this.obj[0].id);
            if(this.obj.length >0){
                console.log(this.obj[0]);
                this.productoEncontrado.producto = this.obj[0].id;
                this.productoEncontrado.codigo = this.obj[0].codigo;
                this.productoEncontrado.nombre = this.obj[0].nombre;
                this.id_barra = '';
                this.obj.forEach((element) =>{
                    console.log(element);
                    for (let xx of element.codigos_barra_producto){
                        console.log("es:" + xx.id);
                        if (xx.codigo_barra == dato){
                            this.id_barra = xx.id;
                        }
                    }
                })
                this.buscarUnidadesProductoData(this.obj[0].id, this.id_barra );
                // Código para mostrar un el Modal desde Vue:
                this.myModal = new Modal(document.getElementById('modalAgregarProducto'), {
                        backdrop: false,
                        keyboard: true
                    });
                this.myModal.show();
            }else{
                console.log("No se encontró");
                notificaciones.round_error_noti($.msg='No se encontró el Código del Producto | Código de Barra');
            }
        }
        else if(tipo === 'id'){
            console.log("id.....");
            console.log(dato);
            this.obj = this.productosBusqueda.results.filter((item) => {
                    return  item.id.includes(dato);
                });
            this.productoEncontrado.producto = this.obj[0].id;
            this.productoEncontrado.nombre = this.obj[0].nombre;
            this.productoEncontrado.codigo = this.obj[0].codigo;
            this.buscarUnidadesProductoData(this.obj[0].id);
            document.getElementById('agregar_detalle_close').click();
            // Código para mostrar un el Modal desde Vue:
            this.myModal = new Modal(document.getElementById('modalAgregarProducto'), {
                    backdrop: false,
                    keyboard: true
                    });
                this.myModal.show();
        }
    },
    accionTecla(event) {
        if (event.key === 'F1') {
        event.preventDefault();
            this.$refs.buscarProductoBtn.click();
        }
        if (event.key === 'F12') {
        event.preventDefault();
            this.$refs.finalizarVentaBtn.click();
        }
      // puedes agregar más acciones para otras teclas aquí
    },
    async getUserData(){
		const response = await fetch(this.URL_TENANT+'/api/user/');
		this.userData = await response.json();
	},
},

beforeMount(){
    this.id_venta = JSON.parse(document.getElementsByTagName('body')[0].getAttribute('data') || '{}');
},
created() {
    // Fetch tasks on page load
	const result = window.location.origin;
    this.URL_TENANT = result;
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    console.log("ID::::");
    console.log(params);
},
mounted(){
    window.addEventListener('keydown', this.accionTecla);
    this.getVentaData();
    this.getDetalleVentaData();
    this.getProductosBusquedaData();
    this.getEmpresaData();
    console.log("REFS:");
    for (var ref in this.$refs) {
        console.log(this.$refs[ref]);
    }
    this.$watch(
        "$refs.child.searchingInputValue",
        () => (this.busqueda = this.$refs.child.searchingInputValue),
    );
    var myModalEl = document.getElementById('agregarDetalleModal')
        myModalEl.addEventListener('shown.bs.modal', () => {
            this.$nextTick(() => {
                this.$refs.busquedaPorTexto.focus();
            });
        });
    var myModalEl2 = document.getElementById('finalizarDetalleVenta')
    myModalEl2.addEventListener('shown.bs.modal', () => {
        this.$nextTick(() => {
            this.$refs.descuento.focus();
            this.$refs.descuento.select();
        });
    });
},
beforeUnmount() {
    window.removeEventListener('keydown', this.accionTecla);
    var myModalEl = document.getElementById('agregarDetalleModal')
    myModalEl.removeEventListener('shown.bs.modal', () => {
        this.$nextTick(() => {
            this.$refs.busquedaPorTexto.focus();
        });
    });
    var myModalEl2 = document.getElementById('finalizarDetalleVenta')
    myModalEl2.removeEventListener('shown.bs.modal', () => {
        this.$nextTick(() => {
            this.$refs.descuento.focus();
            this.$refs.descuento.select();
        });
    });
},
}
</script>
